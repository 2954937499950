import React from 'react';

import { Layout, PageWrap, Section } from 'src/modules/layout';

const NotFoundPage = () => (
  <Layout headOptions={{ title: 'Not found' }}>
    <Section withPadding>
      <PageWrap>
        <h1>Η σελίδα δεν βρέθηκε</h1>
        <p>
          Η σελίδα που αναζητήσατε μπορεί να έχει διαγραφεί, να έχει μετονομαστεί ή να μην είναι προσβάσιμη προσωρινά.
        </p>
      </PageWrap>
    </Section>
  </Layout>
);

export default NotFoundPage;
